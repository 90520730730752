/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withPrefix, Link, graphql } from 'gatsby';

// Layouts
import Default from '../../layouts/default';
import Seo from '../../layouts/seo';

// Components
import Section from '../../components/section';
import Form from '../../components/form';
import Table from '../../components/table';
import PageHeader from '../../components/page-header';
import InputField from '../../components/input-field';
import ResultListing from '../../components/result-listing';
import Tips from '../../components/tips';
import SummaryCard from '../../components/summary-card';
import ShareResults from '../../components/share-results';
import Content, { HTMLContent } from "../../components/content-renderer";

// Helpers
import withShareResults from '../../helpers/with-share-results';
import { formatNumber } from '../../helpers/helpers';
import { checkNull } from '../../helpers/check-null';

const AdjustableRateMortgage = (props) => {
	const { getShareLink } = withShareResults();
	const PageContent = HTMLContent || Content;

	const pageInfo = props.data.toolInfo;
    const definitions = props.data.definitions.nodes;
    const headerImage = pageInfo.frontmatter.image.relativePath;

	// React Redux hooks
	const dispatch = useDispatch();
    const query = useSelector(state => state.adjustableRateMortgage);

	useEffect(() => {
		dispatch({ type: 'APPLY_ADJUSTABLE_MORTGAGE_URL_PARAMS' });

        return () => {
            dispatch({ type: 'RESET_ADJUSTABLE_MORTGAGE_SELECTIONS' });
        };
    }, []);

	// Interest rate per payment (months)
	const initialInterestPerMonth = query.initialInterest / 12 / 100;
	const adjustedInterestPerMonth = query.adjustedInterest / 12 / 100;

	// Update period before adjustment if user selects months checkbox
	const calcPeriodBeforeAdjustment = query.periodMonths ? query.periodBeforeAdjustment : query.periodBeforeAdjustment * 12;

	// Total number of payments (months)
	const initialTotalPayments = query.period * 12;
	const adjustedTotalPayments = initialTotalPayments - calcPeriodBeforeAdjustment;

	// Initial monthly repayment amount
	const calcInitialMonthlyRepayment = query.amount * ((initialInterestPerMonth * Math.pow(1 + initialInterestPerMonth, initialTotalPayments)) / (Math.pow(1 + initialInterestPerMonth, initialTotalPayments) - 1));
	
	// Initial total amount remaining after n years
	const calcInitialTotalRemainingPerYear = (n) => {
		let result = (query.amount * Math.pow(1 + initialInterestPerMonth, n)) - (calcInitialMonthlyRepayment * ((Math.pow(1 + initialInterestPerMonth, n) - 1) / initialInterestPerMonth));
		return result;
	};

	// Total amount remaining after initial interest period ends
	const adjustedAmount = calcInitialTotalRemainingPerYear(calcPeriodBeforeAdjustment);

	// Adjusted monthly repayment amount
	const calcAdjustedMonthlyRepayment = adjustedAmount * ((adjustedInterestPerMonth * Math.pow(1 + adjustedInterestPerMonth, adjustedTotalPayments)) / (Math.pow(1 + adjustedInterestPerMonth, adjustedTotalPayments) - 1));
	
	// Total amount to be repaid
	const calcTotalRepayable = (calcInitialMonthlyRepayment * 12 * (query.periodMonths ? (query.periodBeforeAdjustment / 12) : query.periodBeforeAdjustment)) + (calcAdjustedMonthlyRepayment * adjustedTotalPayments);

	// Array of table rows for yearly breakdown
	let initialYearlyBreakdownRow = [];

	initialYearlyBreakdownRow.push(
		<tr>
			<td>Starting balance</td>
			<td>{formatNumber(query.amount)}</td>
			<td>-</td>
		</tr>
	)
	
	for (let i = 0; i < (query.periodMonths ? Math.ceil(query.periodBeforeAdjustment / 12) : query.periodBeforeAdjustment); i++) {
		const previousRemaining = calcInitialTotalRemainingPerYear((i * 12));
		const currentRemaining = calcInitialTotalRemainingPerYear(((i+1) * 12));

		initialYearlyBreakdownRow.push(
			<tr key={i}>
				<td>{i+1} year{i+1 > 1 ? 's' : ''}</td>
				<td>{formatNumber(currentRemaining)}</td>
				<td>{i === 0 ? formatNumber(query.amount - currentRemaining) : formatNumber(previousRemaining - currentRemaining)}</td>
			</tr>
		);
	}

	// Adjusted total amount remaining after n years
	const calcAdjustedTotalRemainingPerYear = (n) => {
		let result = (adjustedAmount * Math.pow(1 + adjustedInterestPerMonth, n)) - (calcAdjustedMonthlyRepayment * ((Math.pow(1 + adjustedInterestPerMonth, n) - 1) / adjustedInterestPerMonth));
		return result;
	};
	
	let adjustedYearlyBreakdownRow = [];
	
	for (let j = 0; j < (query.periodMonths ? query.period - Math.ceil(query.periodBeforeAdjustment / 12) : query.period - query.periodBeforeAdjustment); j++) {
		const previousRemaining = calcAdjustedTotalRemainingPerYear((j * 12));
		const currentRemaining = calcAdjustedTotalRemainingPerYear(((j+1) * 12));

		adjustedYearlyBreakdownRow.push(
			<tr key={j}>
				<td>{j + parseFloat(query.periodMonths ? Math.ceil(query.periodBeforeAdjustment / 12) : query.periodBeforeAdjustment) + 1} year{j + parseFloat(query.periodMonths ? Math.ceil(query.periodBeforeAdjustment / 12) : query.periodBeforeAdjustment) + 1 > 1 ? 's' : ''}</td>
				<td>{formatNumber(currentRemaining)}</td>
				<td>{formatNumber(previousRemaining - currentRemaining)}</td>
			</tr>
		);
	}

	// Calculate how much paid back per pound borrowed
    const payRate = formatNumber(checkNull(calcTotalRepayable / query.amount));
    
    return (
        <Default>
            <Seo
                title={pageInfo.frontmatter.title}
                description={pageInfo.frontmatter.description}
            />
			
			<PageHeader
				heading={pageInfo.frontmatter.title}
				imageSource={`${withPrefix("/")}img/${headerImage}`}
				modalContent={(
					<PageContent content={pageInfo.html} />
				)}
				description={pageInfo.frontmatter.description}
				breadcrumbs={[
                    {name: 'Home', url: '/'},
                    {name: 'Property', url: '/property'},
                ]}
			/>

			<Section label='Calculator'>
				<Form>
					<Form.Fieldset>
						<Form.Row>
							<InputField
								id='amount'
								required
								labelText='Total Mortgage'
								modalHeading={definitions[4].frontmatter.title}
								modalContent={<PageContent content={definitions[4].html} />}
								placeholder="0"
								symbol='currency'
								min={0}
								onChange={(event) => dispatch({
                                    type: 'SET_ADJUSTABLE_MORTGAGE_AMOUNT',
                                    payload: event.target.value,
                                })}
								value={query.amount}
							/>

							<InputField
								id='period'
								required
								labelText='Repayment period (years)'
								modalHeading={definitions[2].frontmatter.title}
								modalContent={<PageContent content={definitions[2].html} />}
								placeholder="0"
								min={0}
								max={50}
								onChange={(event) => dispatch({
                                    type: 'SET_ADJUSTABLE_MORTGAGE_PERIOD',
                                    payload: event.target.value,
                                })}
								value={query.period}
							/>
							
							<InputField
								id='initial-interest'
								required
								labelText='Initial interest rate'
								modalHeading={definitions[1].frontmatter.title}
								modalContent={<PageContent content={definitions[1].html} />}
								placeholder="0"
								symbol='percentage'
								min={0}
								max={100}
								onChange={(event) => dispatch({
                                    type: 'SET_ADJUSTABLE_MORTGAGE_INITIAL_INTEREST',
                                    payload: event.target.value,
                                })}
								value={query.initialInterest}
							/>
						</Form.Row>	

						<Form.Row>
							<InputField
								id='initial-period'
								required
								labelText={`Period at initial rate ${query.periodMonths ? '(months)' : '(years)'}`}
								modalHeading={definitions[3].frontmatter.title}
								modalContent={<PageContent content={definitions[3].html} />}
								placeholder="0"
								min={0}
								max={50}
								onChange={(event) => dispatch({
                                    type: 'SET_ADJUSTABLE_MORTGAGE_PERIOD_BEFORE_ADJUSTMENT',
                                    payload: event.target.value,
                                })}
								value={query.periodBeforeAdjustment}
								checkboxText='Months'
								checkboxOnChange={() => dispatch({
                                    type: 'SET_ADJUSTABLE_MORTGAGE_PERIOD_MONTHS',
                                    payload: !query.periodMonths,
                                })}
								checkboxValue={query.periodMonths}
							/>
							
							<InputField
								id='adjusted-interest'
								required
								labelText='Adjusted interest rate'
								modalHeading={definitions[0].frontmatter.title}
								modalContent={<PageContent content={definitions[0].html} />}
								placeholder="0"
								symbol='percentage'
								min={0}
								max={100}
								onChange={(event) => dispatch({
                                    type: 'SET_ADJUSTABLE_MORTGAGE_ADJUSTED_INTEREST',
                                    payload: event.target.value,
                                })}
								value={query.adjustedInterest}
							/>
						</Form.Row>
					</Form.Fieldset>
				</Form>
			</Section>

			<Section
				heading='Your results'
				label='Calculation results'
				variants={['padded', 'secondary']}
			>
				<ResultListing>
					<ResultListing.Item 
						heading='Initial repayment'
						result={`${formatNumber(checkNull(calcInitialMonthlyRepayment))}`}
						subheading='per month'
					/>

					<ResultListing.Item 
						heading='Adjusted repayment'
						result={`${formatNumber(checkNull(calcAdjustedMonthlyRepayment))}`}
						subheading='per month'
						/>

					<ResultListing.Item 
						heading='Total repayable'
						result={`${formatNumber(checkNull(calcTotalRepayable))}`}
						modalHeading={definitions[5].frontmatter.title}
						modalContent={<PageContent content={definitions[5].html} />}
					/>

					<ResultListing.Item 
						heading='Amount repaid'
						result={`${payRate}`}
						subheading='per £1 borrowed'
					/>
				</ResultListing>

				{(query.amount !== 0 && query.initialInterest !== 0 && query.period !== 0) && (
					<SummaryCard heading='Yearly repayment breakdown'>
						<Table describedBy='results-table-heading'>
							<Table.Header>
								<th>Time elapsed</th>
								<th>Mortgage remaining</th>
								<th>Repaid this year</th>
							</Table.Header>
							<tbody>
								{initialYearlyBreakdownRow}
								{adjustedYearlyBreakdownRow}
							</tbody>
						</Table>
					</SummaryCard>
				)}

				<div className='summary-card__controls'>
					<ShareResults url={getShareLink(query)} />
				</div>
			</Section>

			<Tips text={(
				<>
					<h2><span role='img' aria-hidden='true'>&#x1F3E0;</span> Buying your next property?</h2>
					<p>Find out how much Stamp Duty you'll pay on your next property purchase with our <Link to="/property/stamp-duty">Stamp Duty Calculator</Link></p>
				</>
			)} />
        </Default>
    );
};

export default AdjustableRateMortgage;

export const AdjustableRateMortgageQuery = graphql`
    query AdjustableRateMortgageQuery($id: String!) {
        toolInfo: markdownRemark(id: { eq: $id }) {
            id
            html
            frontmatter {
                description
                image {
                    relativePath
                }
                title
            }
        }
		definitions: allMarkdownRemark(
            filter: {fileAbsolutePath: {
                regex: "/src/definitions/total-mortgage|src/definitions/mortgage-repayment-period|src/definitions/initial-interest-rate|src/definitions/period-at-initial-rate|src/definitions/adjusted-interest-rate|/src/definitions/total-repayable/"
            }},
            sort: {order: ASC, fields: frontmatter___title}
        ) {
            nodes {
                html
                frontmatter {
                    title
                }
            }
        }
    }
`;